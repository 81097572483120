import "./App.css";
import "./custom.css";
import Main from "./components/Main";
import NewCustomer from "./components/NewCustomer";
import ExistingCustomer from "./components/ExistingCustomer";
import FinalScreen from "./components/FinalScreen";
import PickServices from "./components/PickServices";
import PickStaff from "./components/PickStaff";
import PickTimes from "./components/PickTimes";
import { useState, useEffect } from "react";
//lib
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const apiURL = "https://lilcasoft.ca/apps/inail/";
  const apiURLNext = "https://inailcheckin-server.lilcasoft.ca/api/";

  const [storeApi, setStoreApi] = useState({});
  const [serviceApi, setServiceApi] = useState({});
  const [customerResponse, setCustomerResponse] = useState({});
  const [customerStatusCode, setCustomerStatusCode] = useState(0); //1 : new | 2: return customer
  const [reloadCustomer, setReloadCustomer] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [availableStaffs, setAvailableStaffs] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  //fetch store api
  const fetchStoreInfo = async () => {
    const response = await axios.get(apiURL + "api/store_info/info");
    setStoreApi(response.data);
  };

  //fetch service api
  const fetchServiceInfo = async () => {
    const response = await axios.get(apiURL + "api/service/category_services");
    setServiceApi(response.data);
  };

  const checkInCustomerNoBooking = async (customerId) => {
    await axios
      .get(apiURLNext + "customer_no_booking_checkin/customer_id/" + customerId)
      .then((response) => {
        window.location.href = "/final_screen";
      });
  };

  //process phone number input
  const processCustomerPhoneInput = async (phoneNumber) => {
    const response = await axios.get(
      apiURLNext + "check_customer_phone/phone/" + phoneNumber
    );

    const customerResponseData = response.data;
    if (customerResponseData.length === 0) {
      // empty array
      setCustomerStatusCode(1);
      toast.success("Greeting. Let's start you up with your first check-in!", {
        position: "bottom-center",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setCustomerResponse(customerResponseData);
      setCustomerStatusCode(2);
      setReloadCustomer(false);
    }
  };

  useEffect(() => {
    fetchStoreInfo();
    fetchServiceInfo();
  }, []);

  return (
    <>
      <div className="appContainer">
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Main
                  processCustomerPhoneInput={processCustomerPhoneInput}
                  customerStatusCode={customerStatusCode}
                  store={storeApi}
                  apiURL={apiURL}
                />
              }
            ></Route>
            <Route
              path={"new_customer/:phoneNumber"}
              element={
                <NewCustomer serviceApi={serviceApi} apiURLNext={apiURLNext} />
              }
            ></Route>
            <Route
              path={"existing_customer/:phoneNumber"}
              element={
                <ExistingCustomer
                  store={storeApi}
                  apiURLNext={apiURLNext}
                  serviceApi={serviceApi}
                  reloadCustomer={reloadCustomer}
                  setReloadCustomer={setReloadCustomer}
                  checkInCustomerNoBooking={checkInCustomerNoBooking}
                  processCustomerPhoneInput={processCustomerPhoneInput}
                  customerResponse={customerResponse}
                />
              }
            ></Route>
            <Route
              path={"pick_services/:customerId"}
              element={
                <PickServices
                  store={storeApi}
                  apiURLNext={apiURLNext}
                  serviceApi={serviceApi}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  setAvailableStaffs={setAvailableStaffs}
                />
              }
            ></Route>
            <Route
              path={"pick_staff/:customerId"}
              element={
                <PickStaff
                  store={storeApi}
                  apiURL={apiURL}
                  apiURLNext={apiURLNext}
                  serviceApi={serviceApi}
                  selectedServices={selectedServices}
                  availableStaffs={availableStaffs}
                  setSelectedStaff={setSelectedStaff}
                  selectedStaff={selectedStaff}
                  setAvailableTimeSlots={setAvailableTimeSlots}
                  availableTimeSlots={availableTimeSlots}
                />
              }
            ></Route>
            <Route
              path={"pick_time/:customerId"}
              element={
                <PickTimes
                  store={storeApi}
                  apiURL={apiURL}
                  apiURLNext={apiURLNext}
                  serviceApi={serviceApi}
                  selectedServices={selectedServices}
                  availableStaffs={availableStaffs}
                  selectedStaff={selectedStaff}
                  setAvailableTimeSlots={setAvailableTimeSlots}
                  availableTimeSlots={availableTimeSlots}
                />
              }
            ></Route>
            <Route path={"final_screen"} element={<FinalScreen />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
